import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import Header from "components/common/Header/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useToken from "hooks/useToken";
import { IBooking } from "interfaces/booking";
import { Link } from "react-router-dom";
import BookingServices from "services/booking";

const Dashboard = () => {
  let line_oa_code = "CLUB_FITTING";
  const { token } = useToken();
  const [booking, setBooking] = useState<IBooking[]>([]);
  const [loading, sertLoading] = useState<boolean>(true);

  useEffect(() => {
    BookingServices.getAllBooking(token as string, line_oa_code)
      .then((res) => {
        setBooking(res.data.data);
        sertLoading(false);
      })
      .catch((err) => {
        sertLoading(false);
      });
  }, []);

  return (
    <>
      <Header
        title="Dashboard"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: "/dashboard",
          },
          {
            label: "Club Fitting",
          },
        ]}
      />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={booking.map((b, index) => {
              let data = [
                index.toString().padStart(20, "0"),
                b.id,
                b.first_name + " " + b.last_name,
                `${b.booking_start_time.substring(
                  0,
                  5
                )} น. - ${b.booking_end_time.substring(0, 5)} น.`,
                b.system,
              ];
              // let data = [
              //   index,
              //   b.id,
              //   b.first_name + " " + b.last_name,
              //   b.services
              //     .map((st) => st.id + "-" + st.name + "-" + st.duration)
              //     .join(","),
              //   `( ${b.booking_start_time.substring(
              //     0,
              //     5
              //   )} น. - ${b.booking_end_time.substring(0, 5)} น. )`,
              // ];
              return {
                title: data.join("|"),
                date: b.booking_date,
              };
            })}
            eventContent={EventDetail}
          />
        </div>
      )}
    </>
  );
};

const EventDetail = ({ event }: { event: { title: string } }) => {
  let data = event.title.split("|");
  let id = data[1];
  let user = data[2];
  // let services = data[3].split(",");
  let time = data[3];
  let system = data[4];
  const content = (
    <Link to={system === "CLUB_FITTING" ? `/booking/club-fitting/${id}` : `/booking/cobra/${id}`}>
      <Box
        sx={{
          p: 1,
          backgroundColor: system === "CLUB_FITTING" ? "#E62E2A" : "#2A529C",
        }}
      >
        <Typography fontWeight={700} display={"inline"}>
          {system === "COBRA" ? "Cobra" : "Club Fitting"}
        </Typography>
        <Typography>
          <Typography fontWeight={700} display={"inline"} fontSize={12}>
            Name:
          </Typography>
          <Typography fontSize={12}>{user}</Typography>
        </Typography>
        {/* <Typography>
          <Typography fontWeight={700} display={"inline"} mr={1}>
            Services:
          </Typography>
          {services.map((s) => {
            let sSplit = s.split("-");
            return (
              <Box
                sx={{
                  display: "inline-block",
                  mr: 1,
                }}
              >
                <Tooltip title={`${sSplit[1]} (${sSplit[2]} min)`}>
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "inline-block",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "6px",
                      minWidth: "30px",
                      minHeight: "30px",
                      padding: "5px",
                      textAlign: "center",
                      color: "#212B36",
                      fontWeight: 500,
                    }}
                  >
                    {sSplit[0]}
                  </Box>
                </Tooltip>
              </Box>
            );
          })}
        </Typography> */}
        <Typography>
          <Typography fontWeight={700} display={"inline"} fontSize={12}>
            Time:
          </Typography>
          <Typography fontSize={12}>{time}</Typography>
        </Typography>
      </Box>
    </Link>
  );
  return content;
};

export default withAuth(withPermission(Dashboard, "MANAGE_CLUB_FITTING"));
